const initState = {
    countCheckRegister: 0,
    isLineLogin: false,
    isRegister: false,
    line_profile: {
        id: '',
        line_display_name: '',
        line_display_image: ''
    },
    user_data: {
        user_id:null,
    },
    user_profile: {
        _id: "",
        employee_id: "",
        line_user_id: "",
        line_display_name: "",
        line_display_image: "",
        first_name: "",
        last_name: "",
        job_position: "",
        area: "",
        phone_number: "",
        // phone_number_2: "",
        one_time_passcode: "",
        deactivate_date_time: "",
        status: "",
        is_active: "",
        language: ""
        
 
  
    }
};

export default {
    state: initState,
    getters: {
        authGetState: state => state,
    },
    mutations: {
        setterLineProfile: (state, payload) => {
            state.line_profile = payload;
        },
        setterUserProfile: (state, payload) => {
            state.user_data = payload;
        },
        setterUserProfile_data: (state, payload) => {
            state.user_profile = payload;
        }
    },
    actions: {}
}
