const initState = {
    data: []
};

export default {
    state: initState,
    getters: {},
    mutations: {},
    actions: {}
}
