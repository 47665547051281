import Vue from 'vue'
import App from './App.vue'
import { i18n } from './i18n'
import './registerServiceWorker'
import router from './router'
import store from './store/store'
import { HTTP } from './utility/http';
import { BootstrapVue } from 'bootstrap-vue'
import OtpInput from "@bachdgvn/vue-otp-input";
import Vuelidate from 'vuelidate'
import "@/styles/index.css"
import 'firebase/auth'


Vue.use(BootstrapVue)
Vue.component("v-otp-input", OtpInput);
Vue.use(Vuelidate)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

async function runner() {
    // console.log("runner");
    const profile = await localStorage.getItem('LINE_LIFF_DATA')
    const JSONProfile = await JSON.parse(profile)
    await HTTP.post(`/api/line-user/update`, JSONProfile)
    // const user_data = await HTTP.get(`/api/register/detail/${JSONProfile.userId}`)
    new Vue({
        i18n,
        router,
        store,
        render: h => h(App)
    }).$mount('#app')

}

const loginSuccess = async profile => {
    localStorage.setItem("LINE_LIFF_DATA", profile)
    await runner()
}

if (window.location.hostname === 'localhost' || window.location.port === '8080') {
    console.log("VUE_APP_VERSION=1.0.6")
    localStorage.setItem('IS_LINE_LIFF', 'Y');
    localStorage.setItem('LINE_LIFF_DATA', '{"userId":"Uc079c5732af5995fa1b3s6c1292b64b51", "displayName":"<{BenzsaN}>", "pictureUrl":"https://profile.line-scdn.net/0hwDVa6ZLpKGtrGAAyikdXPFddJgYcNi4jEy0wWBtMdghEK29pX3xiCBxNJFwRfTw7VypiBR4cdV1F","statusMessage": "","email": ""}');
    runner();
} else {
    console.log("VUE_APP_VERSION=1.0.6")
    // eslint-disable-next-line no-undef
    liff.init({ liffId: process.env.VUE_APP_LINE_LIFF_ID })
        .then(async () => {
            // eslint-disable-next-line no-undef
            if (liff.isLoggedIn()) {
                // eslint-disable-next-line no-undef
                const profile = await liff.getProfile()
                // eslint-disable-next-line no-undef
                const email = liff.getDecodedIDToken().email
                profile.email = email || ""
                loginSuccess(JSON.stringify(profile))
            } else {
                if (window.line_liff_origin_path) {
                    localStorage.setItem("LINE_LIFF_ORIGIN_PATH", window.line_liff_origin_path)
                }
                // eslint-disable-next-line no-undef
                liff.login()
            }
        })
        .catch(err => {
            console.log(err)
        }) // alert("Error 01 " + JSON.stringify(err))
}
