<template>
  <div v-if="isLoading" class="text-center">
    <Loading />
  </div>
  <router-view v-else />
</template>

<script>
import Loading from "./components/Loading";
import { HTTP } from "./utility/http";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  async mounted() {
    const is_line_liff = localStorage.getItem("IS_LINE_LIFF");
    if (is_line_liff === "Y") {
      try {
        const LINE_LIFF_DATA = localStorage.getItem("LINE_LIFF_DATA");
        const { userId, displayName, pictureUrl } = JSON.parse(LINE_LIFF_DATA);
        // const USER_DATA = localStorage.getItem("USER_DATA");

        this.$store.commit("setterLineProfile", {
          id: userId || "",
          line_display_name: displayName || "",
          line_display_image: pictureUrl || "",
        });

        const { data } = await HTTP.get(
          `/api/register/detail/${this.$store.state.auth.line_profile.id}`
        );

        // console.log("object :>> ", data.data);
        // console.log("object1 :>> ", this.$store.state.auth.line_profile.id);

        if (data.data !== null) {
          this.$store.commit("setterUserProfile_data", {
            answer: data.data.answer,
            coin: data.data.coin,
            created_at: data.data.created_at,
            deleted_at: data.data.deleted_at,
            first_name: data.data.first_name,
            is_active: data.data.is_active,
            last_name: data.data.last_name,
            line_display_image: data.data.line_display_image,
            line_display_name: data.data.line_display_name,
            line_user_id: data.data.line_user_id,
            member_id: data.data.member_id,
            phone_number: data.data.phone_number,
            status: data.data.status,
            updated_at: data.data.updated_at,
            _id: data.data._id,
          });
          this.$router.push(`/connected`);
        } else {
          this.$router.push(`/`);
        }

        this.isLoading = false;
      } catch (e) {
        alert("Error LINE LIFF--> " + e);
      }
    } else {
      // TODO: Line Login
      try {
        const LINE_LIFF_DATA = localStorage.getItem("LINE_LIFF_DATA");
        const { userId, displayName, pictureUrl } = JSON.parse(LINE_LIFF_DATA);

        this.$store.commit("setterLineProfile", {
          id: userId || "",
          line_display_name: displayName || "",
          line_display_image: pictureUrl || "",
        });
        const { data } = await HTTP.get(
          `/api/register/detail/${this.$store.state.auth.line_profile.id}`
        );
        // console.log("object :>> ", data.data);
        if (data.data !== null) {
          this.$store.commit("setterUserProfile_data", {
            answer: data.data.answer,
            coin: data.data.coin,
            created_at: data.data.created_at,
            deleted_at: data.data.deleted_at,
            first_name: data.data.first_name,
            is_active: data.data.is_active,
            last_name: data.data.last_name,
            line_display_image: data.data.line_display_image,
            line_display_name: data.data.line_display_name,
            line_user_id: data.data.line_user_id,
            member_id: data.data.member_id,
            phone_number: data.data.phone_number,
            status: data.data.status,
            updated_at: data.data.updated_at,
            _id: data.data._id,
          });
          this.$router.push(`/connected`);
        } else {
          this.$router.push(`/`);
        }
        this.isLoading = false;
        
      } catch (e) {
        alert("Error LINE LIFF--> DEV " + e);
      }
    }
  },
};
</script>
