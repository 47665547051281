import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'main',
        meta: { title: 'WallStreetEnglish ' },
        component: () => import(/* webpackChunkName: "main" */ '../views/Term.vue')
    },
    {
        path: '/report',
        name: 'main',
        meta: { title: 'WallStreetEnglish ' },
        component: () => import(/* webpackChunkName: "main" */ '../views/ErrReport.vue')
    },
    {
        path: '/report/number',
        name: 'main',
        meta: { title: 'WallStreetEnglish ' },
        component: () => import(/* webpackChunkName: "main" */ '../views/ErrReport1.vue')
    },
    {
        path: '/register',
        name: 'Register',
        meta: { title: 'WallStreetEnglish ' },
        component: () => import(/* webpackChunkName: "main" */ '../views/RegisterForm.vue')
    },
    {
        path: '/connected',
        name: 'Connected',
        meta: { title: 'WallStreetEnglish' },
        component: () => import(/* webpackChunkName: "main" */ '../views/Connected.vue')
    },
    {
        path: '/passsms',
        name: 'passsms',
        meta: { title: 'WallStreetEnglish' },
        component: () => import(/* webpackChunkName: "main" */ '../views/PassSms.vue')
    },


];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,

    routes,

});
router.beforeEach((to, from, next) => {
    document.title = to.meta.title

    next()
});
export default router
