import Vue from 'vue'
import Vuex from 'vuex'

import authStore from './auth/state'
import coreStore from './core/state'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth: authStore,
        core: coreStore
    }
})
