import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const messages = {
  'en': require('./locales/en.json'),
  'th': require('./locales/th.json')
}
export const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'th', // set fallback locale
  messages //set locale messages
})
